var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");
'use strict';$CLJS.d1=function(a,b){var c=$CLJS.EV(b);return $CLJS.n(c)?$CLJS.b_(a,c):$CLJS.$Z(a,b)};$CLJS.e1=function(a){var b=$CLJS.XA(a);b=b instanceof $CLJS.M?b.S:null;switch(b){case "dispatch-type/nil":return null;case "field":return $CLJS.sO.h($CLJS.ID(a));case "metadata/column":return $CLJS.bZ.h(a);case "mbql/join":return $CLJS.ZD.h(a);default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};
$CLJS.f1=function(a,b){return $CLJS.ha.j?$CLJS.ha.j("%s__%s",a,b):$CLJS.ha.call(null,"%s__%s",a,b)};$CLJS.g1=function(a,b){var c=$CLJS.e1(b);if($CLJS.n(c))a=c;else a:{var d=$CLJS.O(b);c=$CLJS.J.g(d,$CLJS.$J);d=$CLJS.J.g(d,$CLJS.aK);if($CLJS.n($CLJS.n(c)?d:c)&&(d=$CLJS.d1(a,d),$CLJS.n(d))){d=$CLJS.U.h(d);a=$CLJS.U.h($CLJS.W0(a,c));a=$CLJS.ha.j?$CLJS.ha.j("%s__via__%s",d,a):$CLJS.ha.call(null,"%s__via__%s",d,a);break a}a=null}return $CLJS.n(a)?$CLJS.f1(a,$CLJS.U.h(b)):$CLJS.U.h(b)};
$CLJS.h1=function(a,b,c,d){var e=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.aK),c);return $CLJS.Wf.j($CLJS.xf,$CLJS.kk.l($CLJS.ef($CLJS.YJ),$CLJS.ef($CLJS.Ki),$CLJS.ef($CLJS.kk.g($CLJS.al,$CLJS.Ki)),$CLJS.H([$CLJS.cf.h(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.Ki),l=$CLJS.J.g(f,$CLJS.YJ);return $CLJS.R.l($CLJS.W0(a,l),$CLJS.y_,k,$CLJS.H([$CLJS.z_,$CLJS.bZ.h(f)]))}),$CLJS.lk.h(function(f){return $CLJS.Cd(e,$CLJS.aK.h(f))}),$CLJS.gD(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.aK),l=$CLJS.J.g(f,
$CLJS.y_),m=$CLJS.J.g(f,$CLJS.z_);f=$CLJS.$Z(a,k);return function v(u){return new $CLJS.ie(null,function(){for(;;){var x=$CLJS.y(u);if(x){if($CLJS.vd(x)){var A=$CLJS.ic(x),C=$CLJS.D(A),G=$CLJS.le(C);return function(){for(var X=0;;)if(X<C){var T=$CLJS.hd(A,X),da=$CLJS.R.l(T,$CLJS.$J,l,$CLJS.H([$CLJS.v_,m,$CLJS.NJ,$CLJS.EY,$CLJS.tY,$CLJS.U.h(T)]));T=G;var oa=$CLJS.R,Ma=oa.j,sb=da;da=$CLJS.g1(a,da);da=d.h?d.h(da):d.call(null,da);oa=Ma.call(oa,sb,$CLJS.IY,da);T.add(oa);X+=1}else return!0}()?$CLJS.oe($CLJS.qe(G),
v($CLJS.jc(x))):$CLJS.oe($CLJS.qe(G),null)}var K=$CLJS.z(x),S=$CLJS.R.l(K,$CLJS.$J,l,$CLJS.H([$CLJS.v_,m,$CLJS.NJ,$CLJS.EY,$CLJS.tY,$CLJS.U.h(K)]));return $CLJS.ae($CLJS.R.j(S,$CLJS.IY,function(){var X=$CLJS.g1(a,S);return d.h?d.h(X):d.call(null,X)}()),v($CLJS.Hc(x)))}return null}},null,null)}($CLJS.T_.v(a,b,f,new $CLJS.h(null,2,[$CLJS.D_,d,$CLJS.x_,!1],null)))})])),c)};$CLJS.i1=function(a,b){return $CLJS.$0.g(a,b)};$CLJS.j1=function(a){return $CLJS.hD("Question {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([a]))]))};
$CLJS.k1=function(a,b){var c=$CLJS.VD.h(b);$CLJS.n(c)||(c=$CLJS.OY.h(b),$CLJS.n(c)||(c=$CLJS.NY.h(b),c=null!=c?$CLJS.S_.h($CLJS.i1(a,$CLJS.VX.h(c))):null));return $CLJS.n(c)?(c=$CLJS.Be($CLJS.sd(c)?$CLJS.AV.h(c):$CLJS.rd(c)?c:null),$CLJS.n(c)?$CLJS.mk.g($CLJS.Xs(Rpa,a,b),c):null):null};$CLJS.l1=new $CLJS.M("metabase.lib.card","force-broken-id-refs","metabase.lib.card/force-broken-id-refs",-868945458);$CLJS.m1=new $CLJS.M(null,"field-ref","field-ref",-1699303428);$CLJS.I_.m(null,$CLJS.iK,function(a,b,c){return $CLJS.zz($CLJS.oD,$CLJS.U)(c)});$CLJS.O_.m(null,$CLJS.iK,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.U);c=$CLJS.J.g(a,$CLJS.oD);return $CLJS.Ra(c)?$CLJS.R.j(a,$CLJS.oD,$CLJS.c1.g($CLJS.xA,b)):a});
$CLJS.T_.m(null,$CLJS.iK,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.J.g(c,$CLJS.VD),f=$CLJS.J.g(c,$CLJS.OY);d=$CLJS.O(d);var k=$CLJS.J.g(d,$CLJS.x_),l=$CLJS.J.g(d,$CLJS.D_);return $CLJS.bf.g($CLJS.S_.v(a,b,c,d),$CLJS.n(k)?$CLJS.h1(a,b,$CLJS.bf.g(e,f),l):null)});$CLJS.L_.m(null,$CLJS.MU,function(a,b){var c=$CLJS.DV(a,b);c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.MU);if($CLJS.n(c)){var d=$CLJS.b_(a,c);a=$CLJS.n(d)?$CLJS.h_.v(a,b,d,$CLJS.i_):null;return $CLJS.n(a)?a:$CLJS.j1(c)}return null});
var Rpa=function(){function a(d,e,f){var k=$CLJS.dk.g($CLJS.Ez(f,$CLJS.eA),$CLJS.m1);f=$CLJS.Ck.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.tA,$CLJS.fj,$CLJS.ij,$CLJS.MJ],null),function(){var l=$CLJS.Ki.h(k);if($CLJS.n(l))try{return $CLJS.W0(d,l)}catch(m){return null}else return null}(),k,new $CLJS.h(null,3,[$CLJS.ij,$CLJS.MJ,$CLJS.NJ,$CLJS.LY,$CLJS.tY,$CLJS.zz($CLJS.tY,$CLJS.U)(k)],null)]));f=$CLJS.n(e)?$CLJS.R.j(f,$CLJS.VY,$CLJS.S0(e)):f;e=$CLJS.n($CLJS.n(!0)?$CLJS.Ra(e)||$CLJS.Ra($CLJS.AY.h($CLJS.b_(d,
$CLJS.S0(e)))):!0)?$CLJS.R.j(f,$CLJS.l1,!0):f;return $CLJS.jk.g($CLJS.wj.h(k),$CLJS.Xh)?$CLJS.R.j(e,$CLJS.YJ,null):e}function b(d,e){return c.j?c.j(d,null,e):c.call(null,d,null,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Q_.m(null,$CLJS.iK,function(a,b,c,d){b=$CLJS.O(d);var e=$CLJS.J.g(b,$CLJS.D_);return $CLJS.mk.g(function(f){var k=$CLJS.qB($CLJS.IY,$CLJS.tY,$CLJS.U)(f);return $CLJS.R.j(f,$CLJS.IY,e.h?e.h(k):e.call(null,k))},$CLJS.k1(a,c))});