var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var k7,l7,m7,nta,ota,pta,p7,q7,s7,t7,qta,rta,sta,v7,w7,x7,tta,uta;k7=function(a){var b=new $CLJS.h(null,3,[$CLJS.AD,$CLJS.p.h($CLJS.LD()),$CLJS.tA,$CLJS.tA.h(a),$CLJS.pi,$CLJS.zz($CLJS.pi,$CLJS.tA)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,b,$CLJS.zz($CLJS.sD,$CLJS.U)(a)],null)};l7=function(a){return $CLJS.R.j(a,$CLJS.ij,$CLJS.z4)};m7=function(a,b){return $CLJS.E.g($CLJS.nD.h(a),$CLJS.El(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FD,$CLJS.mD,$CLJS.ED],null)))};
nta=function(a){return $CLJS.M0(a,new $CLJS.h(null,1,[$CLJS.FD,$CLJS.mh],null))};
ota=function(a,b){var c=$CLJS.Me(function(e){return $CLJS.tG(function(f){return $CLJS.E.g($CLJS.J.g(f,e),a)},b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IY,$CLJS.U],null));if($CLJS.n(c))return c;c=$CLJS.Mz($CLJS.Ex);if($CLJS.n($CLJS.Lz("metabase.lib.field",c))){var d=$CLJS.hD("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([a])),$CLJS.xh.l($CLJS.H([$CLJS.mk.g($CLJS.IY,b)]))]));d instanceof Error?$CLJS.Kz("metabase.lib.field",c,$CLJS.xv(),d):$CLJS.Kz("metabase.lib.field",
c,$CLJS.xv.l($CLJS.H([d])),null)}return null};
pta=function(a,b,c){if($CLJS.n(n7))return null;var d=n7;n7=!0;try{var e=$CLJS.E1(a,b),f=$CLJS.n(e)?$CLJS.DV(a,e):$CLJS.DV(a,b),k=function(){var m=$CLJS.o7.h(f);if($CLJS.n(m))return m;m=$CLJS.Bz(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mU,$CLJS.AV],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.MU.h(f);if($CLJS.n(u))return u;u=$CLJS.dO.h(f);if($CLJS.n(u))return u;u=$CLJS.YO.h(f);return $CLJS.n(u)?u:$CLJS.VD.h(f)}())?$CLJS.U_.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.Mz($CLJS.Ex);if($CLJS.n($CLJS.Lz("metabase.lib.field",
m))){var t=$CLJS.hD("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.xh.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.Kz("metabase.lib.field",m,$CLJS.xv(),t):$CLJS.Kz("metabase.lib.field",m,$CLJS.xv.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?ota(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.f3($CLJS.dk.l(l,$CLJS.Ki,$CLJS.H([$CLJS.aK,$CLJS.MV,$CLJS.HV])),null),$CLJS.U,function(){var m=$CLJS.IY.h(l);return $CLJS.n(m)?m:$CLJS.U.h(l)}()),
$CLJS.NJ,$CLJS.uY):l:null}finally{n7=d}};
p7=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.sO);var f=$CLJS.I(c,2,null);c=$CLJS.Ck.l($CLJS.H([function(){var k=$CLJS.tA.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.tA,k],null):null}(),function(){var k=$CLJS.zz($CLJS.pi,$CLJS.tA)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.pi,k],null):null}(),function(){var k=$CLJS.sQ.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.MV,k],null):null}(),function(){var k=$CLJS.tF.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.HV,k],null):null}(),$CLJS.Ad(f)?function(){var k=$CLJS.K1(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.ij,$CLJS.MJ,$CLJS.U,$CLJS.p.h(f)],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.ij,$CLJS.MJ,$CLJS.U,$CLJS.p.h(f)],null):function(){var k=pta(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.ij,$CLJS.MJ,$CLJS.U,$CLJS.p.h(f)],null)}()]));return $CLJS.n(d)?$CLJS.f3(c,d):c};
q7=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.HV);return $CLJS.n($CLJS.n(b)?$CLJS.Cd($CLJS.qG,b):b)?$CLJS.bj:$CLJS.zz($CLJS.pi,$CLJS.tA)(a)};
$CLJS.r7=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.AD),l=$CLJS.J.g(f,$CLJS.tA),m=$CLJS.J.g(f,$CLJS.sQ),t=$CLJS.J.g(f,$CLJS.pi);e=$CLJS.J.g(f,$CLJS.sO);var u=$CLJS.J.g(f,$CLJS.DM),v=$CLJS.J.g(f,$CLJS.tF),x=$CLJS.Ck.l;k=new $CLJS.h(null,2,[$CLJS.ij,$CLJS.MJ,$CLJS.z0,k],null);f=$CLJS.oD.h(f);a=$CLJS.n(f)?f:$CLJS.h_.j(a,b,d);c=x.call($CLJS.Ck,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.oD,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.pi,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.tA,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.HV,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.MV,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.$J,u):m;return $CLJS.n(e)?$CLJS.f3(u,e):u};s7=function(a,b,c){return $CLJS.n($CLJS.Me(function(d){return $CLJS.E.g($CLJS.ei.h(d),c)},a))?$CLJS.mk.g(function(d){var e=$CLJS.Cd(d,b)?$CLJS.dk.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.ei.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
t7=function(a){var b=$CLJS.n($CLJS.l1.h(a))?null:function(){var e=$CLJS.NJ.h(a),f=new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.LY,null,$CLJS.SY,null,$CLJS.uY,null],null),null);return f.h?f.h(e):f.call(null,e)}(),c=$CLJS.Ck.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.AD,$CLJS.p.h($CLJS.LD()),$CLJS.tA,$CLJS.tA.h(a),$CLJS.pi,q7(a)],null),function(){var e=function(){var f=$CLJS.Ra(b);return f?(f=$CLJS.Ra($CLJS.$J.h(a)))?(f=$CLJS.jk.g($CLJS.EY,$CLJS.NJ.h(a)))?$CLJS.WY.h(a):f:f:f}();return $CLJS.n(e)?new $CLJS.h(null,
1,[$CLJS.sO,e],null):null}(),function(){var e=$CLJS.e1(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.sO,e],null):null}(),function(){var e=$CLJS.HV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.tF,e],null):null}(),function(){var e=$CLJS.LV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.LV,e],null):null}(),function(){var e=$CLJS.MV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.sQ,e],null):null}(),function(){var e=$CLJS.$J.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.DM,e],null):null}()])),
d=($CLJS.n(b)?$CLJS.zz($CLJS.IY,$CLJS.U):$CLJS.zz($CLJS.Ki,$CLJS.U))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,c,d],null)};qta=function(a,b){return $CLJS.ff(function(c){return $CLJS.E.g($CLJS.NJ.h(c),$CLJS.CY)},$CLJS.U_.v(a,b,$CLJS.DV(a,b),new $CLJS.h(null,3,[$CLJS.B_,!1,$CLJS.H_,!0,$CLJS.x_,!1],null)))};rta=new $CLJS.M("lib","simple-name","lib/simple-name",-734346338);sta=new $CLJS.M("metabase.lib.field","field-values-search-info","metabase.lib.field/field-values-search-info",-468454179);
$CLJS.u7=new $CLJS.M(null,"search-field-id","search-field-id",-699326724);v7=new $CLJS.M("metabase.lib.field","field-values-search-info.has-field-values","metabase.lib.field/field-values-search-info.has-field-values",1753198226);w7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.o7=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);x7=new $CLJS.M("metabase.lib.field","simple-display-name","metabase.lib.field/simple-display-name",494797345);
tta=new $CLJS.M(null,"earliest","earliest",-1928154382);uta=new $CLJS.M(null,"latest","latest",24323665);$CLJS.N0.m(null,$CLJS.nF,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mh.h(b),$CLJS.M0(c,new $CLJS.h(null,2,[$CLJS.tF,$CLJS.mh,$CLJS.sQ,nta],null)),a],null)});
var n7=!1,y7=function y7(a,b){var d=$CLJS.W0(a,w7.h(b));a=$CLJS.n(w7.h(d))?y7.g?y7.g(a,d):y7.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.U),f=$CLJS.J.g(a,$CLJS.oD);return $CLJS.nk.j($CLJS.R.j($CLJS.nk.j($CLJS.R.j(b,rta,$CLJS.U.h(b)),$CLJS.U,function(k){return[$CLJS.p.h(e),".",$CLJS.p.h(k)].join("")}),x7,$CLJS.oD.h(b)),$CLJS.oD,function(k){return[$CLJS.p.h(f),": ",$CLJS.p.h(k)].join("")})};$CLJS.M_.m(null,$CLJS.MJ,function(a,b,c){return q7(c)});
$CLJS.M_.m(null,$CLJS.nF,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.tF);$CLJS.I(c,2,null);c=p7(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.HV,d):c;return $CLJS.N_.j(a,b,c)});$CLJS.O_.m(null,$CLJS.MJ,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.U);return $CLJS.R.j(a,$CLJS.U,b)});$CLJS.O_.m(null,$CLJS.nF,function(a,b,c){var d=p7(a,b,c);b=$CLJS.r7(a,b,d,c);return $CLJS.n(w7.h(b))?y7(a,b):b});
$CLJS.I_.m(null,$CLJS.MJ,function(a,b,c,d){function e(T){var da=$CLJS.f5(l,k);return $CLJS.ha.j?$CLJS.ha.j("%s: %s",T,da):$CLJS.ha.call(null,"%s: %s",T,da)}function f(T){var da=$CLJS.cA($CLJS.Gz($CLJS.Xg(u),"-"," "));return $CLJS.ha.j?$CLJS.ha.j("%s: %s",T,da):$CLJS.ha.call(null,"%s: %s",T,da)}var k=$CLJS.O(c),l=$CLJS.J.g(k,$CLJS.MV),m=$CLJS.J.g(k,$CLJS.$J),t=$CLJS.J.g(k,$CLJS.aK),u=$CLJS.J.g(k,$CLJS.ei),v=$CLJS.J.g(k,$CLJS.WY),x=$CLJS.J.g(k,x7),A=$CLJS.J.g(k,$CLJS.U),C=$CLJS.J.g(k,$CLJS.F5),G=$CLJS.J.g(k,
$CLJS.oD),K=$CLJS.J.g(k,w7),S=$CLJS.c1.g($CLJS.xA,A);c=function(){if($CLJS.n(x))return x;if($CLJS.n($CLJS.n(K)?null==G||$CLJS.E.g(G,S):K)){a:{var T=$CLJS.O(k);var da=$CLJS.J.g(T,$CLJS.oD);T=$CLJS.J.g(T,w7);for(da=new $CLJS.Vd(null,da,null,1,null);;)if($CLJS.n(T)){T=$CLJS.W0(a,T);var oa=$CLJS.O(T);T=$CLJS.J.g(oa,$CLJS.oD);oa=$CLJS.J.g(oa,w7);da=$CLJS.Yd.g(da,T);T=oa}else break a}da=$CLJS.Le($CLJS.Sa,da)?$CLJS.is(": ",da):null}else da=null;return $CLJS.n(da)?da:$CLJS.n(G)?G:"string"===typeof A?S:$CLJS.p.h(A)}();
var X=$CLJS.E.g(d,$CLJS.i_)&&-1==c.indexOf(" → ")?function(){if($CLJS.n(m)){var T=$CLJS.W0(a,m);$CLJS.n(T)?T=$CLJS.b3($CLJS.oD.h($CLJS.k_.j(a,b,T))):(T=$CLJS.d1(a,t),T=$CLJS.h_.v(a,b,T,d))}else T=null;return $CLJS.n(T)?T:$CLJS.n(v)?v:$CLJS.e1(k)}():null;c=$CLJS.n(X)?[$CLJS.p.h(X)," → ",$CLJS.p.h(c)].join(""):c;return $CLJS.n(function(){var T=$CLJS.jk.g(d,$CLJS.i_);return T?C:T}())?c:$CLJS.n($CLJS.n(u)?$CLJS.jk.g(c,f(S)):u)?f(c):$CLJS.n($CLJS.n(l)?$CLJS.jk.g(c,e(S)):l)?e(c):c});
$CLJS.I_.m(null,$CLJS.nF,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.sQ);var k=$CLJS.J.g(f,$CLJS.sO),l=$CLJS.J.g(f,$CLJS.tF);f=$CLJS.J.g(f,$CLJS.DM);$CLJS.I(c,2,null);c=p7(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.WY,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.ei,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.MV,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.$J,f):e;return $CLJS.n(e)?$CLJS.h_.v(a,b,e,d):$CLJS.MD("[Unknown Field]")});
$CLJS.J_.m(null,$CLJS.MJ,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.U)});$CLJS.J_.m(null,$CLJS.nF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=p7(a,b,c);return $CLJS.n(c)?$CLJS.K_.j(a,b,c):"unknown_field"});
$CLJS.P_.m(null,$CLJS.MJ,function(a,b,c){return $CLJS.Ck.l($CLJS.H([function(){var d=$CLJS.mH($CLJS.P_,$CLJS.Oh);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),new $CLJS.h(null,2,[$CLJS.oD,$CLJS.h_.j(a,b,c),$CLJS.j_,$CLJS.h_.v(a,b,c,$CLJS.i_)],null),$CLJS.E.g($CLJS.NJ.h(c),$CLJS.LY)?function(){var d=$CLJS.VY.h(c);return $CLJS.n(d)?(d=$CLJS.b_(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.Oj,new $CLJS.h(null,2,[$CLJS.U,$CLJS.U.h(d),$CLJS.oD,$CLJS.U.h(d)],null)],null):null):null}():null]))});
$CLJS.q0.m(null,$CLJS.nF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.tF.h(b)});$CLJS.q0.m(null,$CLJS.MJ,function(a){return $CLJS.HV.h(a)});
$CLJS.p0.m(null,$CLJS.nF,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Cd($CLJS.qG,b),e=$CLJS.qB($CLJS.LV,$CLJS.pi,$CLJS.tA)(c);c=$CLJS.R.l(c,$CLJS.tF,b,$CLJS.H([$CLJS.pi,d?$CLJS.bj:e,$CLJS.LV,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,c,a],null)}b=$CLJS.LV.h(c);c=$CLJS.n(b)?$CLJS.dk.g($CLJS.R.j(c,$CLJS.pi,b),$CLJS.LV):c;c=$CLJS.dk.g(c,$CLJS.tF);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,c,a],null)});
$CLJS.p0.m(null,$CLJS.MJ,function(a,b){return $CLJS.n(b)?$CLJS.R.l(a,$CLJS.HV,b,$CLJS.H([$CLJS.LV,$CLJS.qB($CLJS.LV,$CLJS.pi,$CLJS.tA)(a)])):$CLJS.dk.l(a,$CLJS.HV,$CLJS.H([$CLJS.LV]))});$CLJS.s0.m(null,$CLJS.nF,function(a,b,c){return $CLJS.t0.j(a,b,p7(a,b,c))});
$CLJS.s0.m(null,$CLJS.MJ,function(a,b,c){if($CLJS.jk.g($CLJS.NJ.h(c),$CLJS.CY)){a=$CLJS.zz($CLJS.pi,$CLJS.tA)(c);b=null==c?null:$CLJS.RM.h(c);if(null==b)var d=null;else try{var e=$CLJS.Kk.h($CLJS.Vi.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,tta),l=$CLJS.J.g(f,uta),m=$CLJS.kpa.l($CLJS.H([$CLJS.n0.h(k),$CLJS.n0.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.Dq.g?$CLJS.Dq.g(1,m):$CLJS.Dq.call(null,1,m))?$CLJS.Di:$CLJS.n($CLJS.Dq.g?$CLJS.Dq.g(31,m):$CLJS.Dq.call(null,31,m))?$CLJS.Pj:$CLJS.n($CLJS.Dq.g?
$CLJS.Dq.g(365,m):$CLJS.Dq.call(null,365,m))?$CLJS.Xi:$CLJS.cj}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.Dz(a,$CLJS.Kk)?$CLJS.vpa:$CLJS.Dz(a,$CLJS.Ik)?$CLJS.upa:$CLJS.Dz(a,$CLJS.Nk)?$CLJS.tpa:$CLJS.xf;d=$CLJS.n(d)?s7(e,$CLJS.Oh,d):e;return $CLJS.n($CLJS.HV.h(c))?s7(d,$CLJS.p_,$CLJS.HV.h(c)):d}return $CLJS.xf});
$CLJS.c5.m(null,$CLJS.nF,function(a){var b=null==a?null:$CLJS.ID(a);b=null==b?null:$CLJS.sQ.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.ij,$CLJS.h5,$CLJS.H([$CLJS.g5,function(c,d){return p7(c,d,a)}]))});$CLJS.c5.m(null,$CLJS.MJ,function(a){var b=null==a?null:$CLJS.MV.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.ij,$CLJS.h5,$CLJS.H([$CLJS.g5,$CLJS.Pe(a)]))});$CLJS.i5.m(null,$CLJS.nF,function(a,b){return $CLJS.JD(a,$CLJS.HD,$CLJS.H([$CLJS.sQ,b]))});
$CLJS.i5.m(null,$CLJS.MJ,function(a,b){return $CLJS.HD(a,$CLJS.MV,b)});$CLJS.j5.m(null,$CLJS.nF,function(a,b,c){return $CLJS.k5.j(a,b,p7(a,b,c))});
$CLJS.j5.m(null,$CLJS.MJ,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.pi);var d=$CLJS.J.g(b,$CLJS.RM),e=$CLJS.J.g(b,$CLJS.wj);if($CLJS.jk.g($CLJS.NJ.h(b),$CLJS.CY)){var f=function(){var m=null==a?null:$CLJS.V0(a);m=null==m?null:$CLJS.dz.h(m);return null==m?null:$CLJS.Cd(m,$CLJS.sQ)}(),k=$CLJS.Bz(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vi,$CLJS.sj],null)),l=$CLJS.d5(b);return function u(t){return new $CLJS.ie(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.vd(v)){var x=$CLJS.ic(v),A=$CLJS.D(x),
C=$CLJS.le(A);return function(){for(var K=0;;)if(K<A){var S=$CLJS.hd(x,K),X=C,T=S;S=m7(S,l)?$CLJS.R.j(T,$CLJS.p_,!0):T;X.add(S);K+=1}else return!0}()?$CLJS.oe($CLJS.qe(C),u($CLJS.jc(v))):$CLJS.oe($CLJS.qe(C),null)}var G=$CLJS.z(v);return $CLJS.ae(function(){var K=G;return m7(G,l)?$CLJS.R.j(K,$CLJS.p_,!0):K}(),u($CLJS.Hc(v)))}return null}},null,null)}($CLJS.Ra(function(){if($CLJS.n(f)){var t=$CLJS.ti.h(k);return $CLJS.n(t)?$CLJS.Fj.h(k):t}return f}())?null:$CLJS.Dz(e,$CLJS.dj)?$CLJS.mk.g(l7,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.e5(),new $CLJS.h(null,2,[$CLJS.oD,$CLJS.MD("Bin every 0.1 degrees"),$CLJS.nD,new $CLJS.h(null,2,[$CLJS.FD,$CLJS.ED,$CLJS.ED,.1],null)],null),new $CLJS.h(null,2,[$CLJS.oD,$CLJS.MD("Bin every 1 degree"),$CLJS.nD,new $CLJS.h(null,2,[$CLJS.FD,$CLJS.ED,$CLJS.ED,1],null)],null),new $CLJS.h(null,2,[$CLJS.oD,$CLJS.MD("Bin every 10 degrees"),$CLJS.nD,new $CLJS.h(null,2,[$CLJS.FD,$CLJS.ED,$CLJS.ED,10],null)],null),new $CLJS.h(null,2,[$CLJS.oD,$CLJS.MD("Bin every 20 degrees"),$CLJS.nD,new $CLJS.h(null,
2,[$CLJS.FD,$CLJS.ED,$CLJS.ED,20],null)],null)],null)):$CLJS.Dz(c,$CLJS.sj)&&!$CLJS.Dz(e,$CLJS.ai)?$CLJS.mk.g(l7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.e5(),new $CLJS.h(null,2,[$CLJS.oD,$CLJS.MD("10 bins"),$CLJS.nD,new $CLJS.h(null,2,[$CLJS.FD,$CLJS.mD,$CLJS.mD,10],null)],null),new $CLJS.h(null,2,[$CLJS.oD,$CLJS.MD("50 bins"),$CLJS.nD,new $CLJS.h(null,2,[$CLJS.FD,$CLJS.mD,$CLJS.mD,50],null)],null),new $CLJS.h(null,2,[$CLJS.oD,$CLJS.MD("100 bins"),$CLJS.nD,new $CLJS.h(null,2,[$CLJS.FD,$CLJS.mD,$CLJS.mD,
100],null)],null)],null)):null)}return $CLJS.xf});$CLJS.mV.m(null,$CLJS.nF,function(a){return a});
$CLJS.mV.m(null,$CLJS.MJ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.NJ);switch(b instanceof $CLJS.M?b.S:null){case "source/aggregations":return b=new $CLJS.h(null,3,[$CLJS.AD,$CLJS.p.h($CLJS.LD()),$CLJS.pi,$CLJS.zz($CLJS.pi,$CLJS.tA)(a),$CLJS.AK,$CLJS.U.h(a)],null),a=$CLJS.z0.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cF,b,a],null);case "source/expressions":return k7(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.sD.h(a))?k7(a):t7(a);default:return t7(a)}});
$CLJS.vta=function(){function a(e,f,k){k=$CLJS.Be($CLJS.mk.g($CLJS.oV,k));var l=qta(e,f),m=$CLJS.Wf.j($CLJS.Sg,$CLJS.wl(function(t){return $CLJS.Q1.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.xf);m=$CLJS.lk.g(m,l);k=$CLJS.n(k)?$CLJS.Wf.j(k,$CLJS.cf.h($CLJS.oV),m):null;return $CLJS.OV.l(e,f,$CLJS.HD,$CLJS.H([$CLJS.VD,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.z7=function(){function a(d,e){return $CLJS.VD.h($CLJS.DV(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.wta=function(){function a(d,e){var f=$CLJS.U_.v(d,e,$CLJS.DV(d,e),new $CLJS.h(null,3,[$CLJS.B_,!1,$CLJS.H_,!1,$CLJS.x_,!1],null)),k=$CLJS.z7.g(d,e);return $CLJS.od(k)?$CLJS.mk.g(function(l){return $CLJS.R.j(l,$CLJS.JY,!0)},f):$CLJS.d3(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Y(v7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dr,$CLJS.gj,$CLJS.xY,$CLJS.cz],null));
$CLJS.Y(sta,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.CD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.u7,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.CD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,v7],null)],null)],null));