var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.registry.js");
'use strict';var Wga,Xga,Yga,Zga,$ga,aha,bha,RF,cha,dha,eha,fha,gha,hha,iha,jha,TF,kha;$CLJS.NF=new $CLJS.M(null,"supported-field","supported-field",-2061545519);Wga=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.OF=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);Xga=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
Yga=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.PF=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);Zga=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.QF=new $CLJS.M(null,"display-info","display-info",-816930907);$ga=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);aha=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);
bha=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);RF=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);cha=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);dha=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);eha=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);fha=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);
gha=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);hha=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);iha=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.SF=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);jha=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);TF=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);
$CLJS.UF=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);kha=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.CE($CLJS.Rw,$CLJS.H([$CLJS.$r,$CLJS.bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aE],null)],null)],null)],null)]));$CLJS.CE($CLJS.oF,$CLJS.H([$CLJS.$r,$CLJS.bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aE],null)],null)],null)],null)]));
$CLJS.AE($CLJS.hF,$CLJS.H([$CLJS.$r,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lE],null)],null)]));$CLJS.AE($CLJS.SE,$CLJS.H([$CLJS.$r,$CLJS.bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aE],null)],null)]));$CLJS.AE($CLJS.wF,$CLJS.H([$CLJS.$r,$CLJS.bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null)],null)]));
$CLJS.AE($CLJS.Fj,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gE],null)],null)]));$CLJS.tE($CLJS.Fj,$CLJS.fE);$CLJS.AE($CLJS.AF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lE],null)],null)]));$CLJS.tE($CLJS.AF,$CLJS.fE);$CLJS.AE($CLJS.ti,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gE],null)],null)]));$CLJS.tE($CLJS.ti,$CLJS.fE);
$CLJS.Y(RF,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mr,new $CLJS.h(null,1,[$CLJS.us,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.us,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.AE($CLJS.ZE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RF],null)]));$CLJS.tE($CLJS.ZE,$CLJS.fE);
$CLJS.AE($CLJS.uF,$CLJS.H([$CLJS.$r,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null)],null)]));$CLJS.AE($CLJS.BF,$CLJS.H([$CLJS.$r,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lE],null)],null)]));$CLJS.AE($CLJS.zF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lE],null)],null)]));
$CLJS.AE($CLJS.IF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lE],null)],null)]));$CLJS.tE($CLJS.zF,$CLJS.fE);$CLJS.tE($CLJS.IF,$CLJS.fE);$CLJS.AE($CLJS.GF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null)],null)]));$CLJS.tE($CLJS.GF,$CLJS.fE);
$CLJS.AE($CLJS.TE,$CLJS.H([$CLJS.$r,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lE],null)],null)]));$CLJS.Y(TF,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.gr,jha,hha,aha,Xga,Wga,$ga,bha,cha,dha,gha,eha,kha,iha,Yga,fha,$CLJS.cl],null));$CLJS.Y($CLJS.OF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.ti,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TF],null)],null));
$CLJS.VF=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.gF,$CLJS.Rw,$CLJS.PF,!1,$CLJS.SF,$CLJS.HE,$CLJS.QF,function(){return new $CLJS.h(null,3,[$CLJS.oD,$CLJS.MD("Count of rows"),$CLJS.YE,$CLJS.MD("Count"),$CLJS.Is,$CLJS.MD("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.gF,$CLJS.zF,$CLJS.NF,$CLJS.Cj,$CLJS.PF,!0,$CLJS.SF,$CLJS.HE,$CLJS.QF,function(){return new $CLJS.h(null,3,[$CLJS.oD,$CLJS.MD("Sum of ..."),$CLJS.YE,$CLJS.MD("Sum"),$CLJS.Is,$CLJS.MD("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.gF,$CLJS.hF,$CLJS.NF,$CLJS.Cj,$CLJS.PF,!0,$CLJS.SF,$CLJS.HE,$CLJS.QF,function(){return new $CLJS.h(null,3,[$CLJS.oD,$CLJS.MD("Average of ..."),$CLJS.YE,$CLJS.MD("Average"),$CLJS.Is,$CLJS.MD("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.gF,$CLJS.AF,$CLJS.NF,$CLJS.Cj,$CLJS.PF,!0,$CLJS.SF,$CLJS.jF,$CLJS.QF,function(){return new $CLJS.h(null,3,[$CLJS.oD,$CLJS.MD("Median of ..."),$CLJS.YE,$CLJS.MD("Median"),$CLJS.Is,$CLJS.MD("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.gF,$CLJS.SE,$CLJS.NF,$CLJS.Zh,$CLJS.PF,!0,$CLJS.SF,$CLJS.HE,$CLJS.QF,function(){return new $CLJS.h(null,3,[$CLJS.oD,$CLJS.MD("Number of distinct values of ..."),$CLJS.YE,$CLJS.MD("Distinct values"),$CLJS.Is,$CLJS.MD("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.gF,$CLJS.IF,$CLJS.NF,$CLJS.Cj,$CLJS.PF,!0,$CLJS.SF,$CLJS.HE,$CLJS.QF,function(){return new $CLJS.h(null,3,[$CLJS.oD,$CLJS.MD("Cumulative sum of ..."),
$CLJS.YE,$CLJS.MD("Sum"),$CLJS.Is,$CLJS.MD("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.gF,$CLJS.oF,$CLJS.PF,!1,$CLJS.SF,$CLJS.HE,$CLJS.QF,function(){return new $CLJS.h(null,3,[$CLJS.oD,$CLJS.MD("Cumulative count of rows"),$CLJS.YE,$CLJS.MD("Count"),$CLJS.Is,$CLJS.MD("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.gF,$CLJS.BF,$CLJS.NF,$CLJS.Cj,$CLJS.PF,
!0,$CLJS.SF,$CLJS.xF,$CLJS.QF,function(){return new $CLJS.h(null,3,[$CLJS.oD,$CLJS.MD("Standard deviation of ..."),$CLJS.YE,$CLJS.MD("SD"),$CLJS.Is,$CLJS.MD("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.gF,$CLJS.ti,$CLJS.NF,$CLJS.Ji,$CLJS.PF,!0,$CLJS.SF,$CLJS.HE,$CLJS.QF,function(){return new $CLJS.h(null,3,[$CLJS.oD,$CLJS.MD("Minimum of ..."),$CLJS.YE,$CLJS.MD("Min"),$CLJS.Is,$CLJS.MD("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.gF,$CLJS.Fj,$CLJS.NF,$CLJS.Ji,$CLJS.PF,!0,$CLJS.SF,$CLJS.HE,$CLJS.QF,function(){return new $CLJS.h(null,3,[$CLJS.oD,$CLJS.MD("Maximum of ..."),$CLJS.YE,$CLJS.MD("Max"),$CLJS.Is,$CLJS.MD("Maximum value of a column")],null)}],null)],null);
$CLJS.Y(Zga,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ij,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jq,$CLJS.UF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gF,$CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.dr],null),$CLJS.cf.h($CLJS.gF),$CLJS.VF)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NF,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.Ii],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PF,$CLJS.vr],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.SF,$CLJS.Ii],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QF,$CLJS.id],null)],null));