var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./metabase.mbql.util.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var TV,VV,XV,YV,Xma,$V,aW,SV,bW,eW,Yma,Zma,$ma,ana,gW,hW,jW,kW,lW,mW,nW,oW,cna,dna,qW,ena,rW,fna,tW,gna,hna,ina,jna,kna,PV;$CLJS.QV=function(a,b){if("string"===typeof b)return PV(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.RV=function(a,b,c){var d=$CLJS.Fl(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Cb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};
TV=function(a){var b=SV;return $CLJS.Iz(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};$CLJS.UV=function(a,b){return $CLJS.Iz(function(c){return function(d,e,f){return $CLJS.n(a.h?a.h(e):a.call(null,e))?c.j?c.j(d,e,f):c.call(null,d,e,f):d}},b)};VV=function(a){if($CLJS.id(a))return a;throw $CLJS.Uh("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.ri,a],null));};
$CLJS.WV=function(a,b,c){return $CLJS.sd(c)?$CLJS.Wf.g(c,function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var A=0;;)if(A<m){var C=$CLJS.hd(l,A),G=$CLJS.I(C,0,null),K=$CLJS.I(C,1,null);C=t;var S=$CLJS.Q,X=G;G=$CLJS.Yd.g(b,G);G=a.g?a.g(G,K):a.call(null,G,K);C.add(new $CLJS.P(null,2,5,S,[X,G],null));A+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),
null)}var u=$CLJS.z(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var A=$CLJS.Yd.g(b,v),C=x;return a.g?a.g(A,C):a.call(null,A,C)}()],null),f($CLJS.Hc(k)))}return null}},null,null)}(c)}()):$CLJS.rd(c)?$CLJS.mk.g($CLJS.Qe(a,$CLJS.z(c)instanceof $CLJS.M?$CLJS.Yd.g(b,$CLJS.z(c)):b),c):c};XV=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.de(a):b;return $CLJS.n(b)?[$CLJS.de(a),"/",$CLJS.Xg(a)].join(""):$CLJS.Xg(a)};
YV=function(a,b){var c=$CLJS.rd(b)&&!$CLJS.uf(b)&&$CLJS.z(b)instanceof $CLJS.M;return c?$CLJS.pd(a)?(b=$CLJS.z(b),a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.z(b)):c};
Xma=function(a,b){return $CLJS.df(function(c){var d=$CLJS.ff;a:try{if($CLJS.ud(c)&&1<=$CLJS.D(c))try{var e=$CLJS.rk.j(c,0,1);if($CLJS.ud(e)&&1===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.n($CLJS.Qe($CLJS.E,a)(f))){var k=$CLJS.rk.g(c,1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[k],null)}else throw $CLJS.Z;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw t;}else throw $CLJS.Z;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw t;}else throw $CLJS.Z;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Z){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.z($CLJS.y(d($CLJS.Sa,l)))},$CLJS.H([b]))};
$V=function(a,b){for(;;)switch(b=$CLJS.Dk.h($CLJS.ff($CLJS.Sa,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.z(b),ZV.h?ZV.h(a):ZV.call(null,a);default:if($CLJS.n($CLJS.Me($CLJS.Qe(YV,a),b))){var c=a;b=Xma(a,b);a=c}else{c=$CLJS.cf.g(ZV,b);if($CLJS.E.g(c,b))return $CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),b);b=c}}};aW=function(a){var b=$CLJS.rd(a);return b?(b=!$CLJS.uf(a))?$CLJS.zz($CLJS.be,$CLJS.Wa)($CLJS.z(a)):b:b};
SV=function(a){return $CLJS.n($CLJS.zz($CLJS.be,$CLJS.Wa)(a))?$CLJS.mh.h($CLJS.Gz(XV(a).toLowerCase(),/_/,"-")):a};bW=function(a,b){var c=aW(b);return $CLJS.n(c)?(b=SV($CLJS.z(b)),$CLJS.pd(a)?(a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,b)):c};
eW=function(a){a=cW.g?cW.g(a,dW):cW.call(null,a,dW);var b=$CLJS.n($CLJS.tA.h(a))?$CLJS.nk.j(a,$CLJS.tA,$CLJS.mh):a;b=$CLJS.n($CLJS.tF.h(a))?$CLJS.nk.j(b,$CLJS.tF,$CLJS.mh):b;return $CLJS.n($CLJS.sQ.h(a))?$CLJS.nk.j(b,$CLJS.sQ,function(c){return $CLJS.n($CLJS.FD.h(c))?$CLJS.nk.j(c,$CLJS.FD,$CLJS.mh):c}):b};
Yma=function(a){return $CLJS.J.j(new $CLJS.h(null,3,[$CLJS.Oh,$CLJS.Pd,$CLJS.Vi,SV,$CLJS.PM,SV],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.Ae([a,b]);b=cW.g?cW.g(b,dW):cW.call(null,b,dW);return d.call(c,b,a)})};
Zma=function(a){a=$CLJS.Wf.j($CLJS.N,$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=SV(c);var d=Yma(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.E.g($CLJS.Vi.h(a),$CLJS.GE)&&$CLJS.Ra($CLJS.PM.h(a))?$CLJS.R.j(a,$CLJS.PM,$CLJS.FF):a};$ma=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=XV(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j(Zma(b),$CLJS.U,c)],null)}),a)};
ana=function(a){a=cW.h?cW.h(a):cW.call(null,a);return fW.h?fW.h(a):fW.call(null,a)};gW=function(a){return $CLJS.RV($CLJS.RV($CLJS.cb(function(b,c){return $CLJS.RV(b,c,$CLJS.mh)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.cC,$CLJS.vga,$CLJS.CN,bna,$CLJS.Yy,$CLJS.ei],null)),$CLJS.fB,ana),$CLJS.RM,$CLJS.tU)};hW=function(a){return $CLJS.Ad(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,a,null],null):a};jW=function(a){return iW.h(hW(a))};
kW=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);return ZV($CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.cf.g(iW,b)))};lW=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,jW(b)],null),$CLJS.cf.g(iW,c))};mW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,jW(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};
nW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,jW(a)],null)};oW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,iW.h(a)],null)};cna=function(a){return $CLJS.yf($CLJS.Cl(fW,pW(a)))};dna=function(a){for(;;)if($CLJS.n(aW(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.Be($CLJS.mk.g(hW,a))};
qW=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.GE);return $CLJS.n(b)?$CLJS.nk.j(a,$CLJS.GE,iW):a};
ena=function(a){return $CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.vd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,qW(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}f=$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ae(new $CLJS.P(null,
2,5,$CLJS.Q,[l,qW(f)],null),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())};rW=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.EP);return $CLJS.n(b)?$CLJS.nk.j(a,$CLJS.EP,ena):a};fna=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.hP);b=$CLJS.Ra(a)?sW.h?sW.h(b):sW.call(null,b):b;return $CLJS.n(a)?rW(b):b};tW=function(a){return $CLJS.pd(a)?$CLJS.y(a):null!=a};
gna=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.JE);var c=$CLJS.J.g(b,$CLJS.tM),d=$CLJS.J.g(b,$CLJS.QN),e=$CLJS.J.g(b,$CLJS.hP);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.J.g(f,$CLJS.QN);var l=$CLJS.VK($CLJS.dk.g(f,$CLJS.QN),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JE,$CLJS.QN],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.nk.j(l,$CLJS.JE,sW):l,t=$CLJS.n(c)?$CLJS.nk.j(m,$CLJS.tM,$CLJS.Qe($CLJS.mk,fW)):m,u=$CLJS.n(e)?$CLJS.nk.j(t,$CLJS.hP,rW):t,v=$CLJS.O(u),x=$CLJS.J.g(v,$CLJS.hP);return $CLJS.n(x)?$CLJS.R.j(fW($CLJS.dk.g(v,
$CLJS.hP)),$CLJS.hP,x):fW(v)}catch(A){if(A instanceof Error)throw m=A,$CLJS.Th($CLJS.hD("Error canonicalizing query: {0}",$CLJS.H([$CLJS.rU(m)])),new $CLJS.h(null,1,[$CLJS.JE,a],null),m);throw A;}};
hna=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.JE),c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.bF);c=$CLJS.J.g(c,$CLJS.VD);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.Wf.j($CLJS.Sg,$CLJS.rB,$CLJS.y($CLJS.ff($CLJS.Sa,function k(e,f){try{if($CLJS.ud(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.ce(l,$CLJS.nF)){var m=$CLJS.F(f,1),t=$CLJS.F(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,m,$CLJS.dk.g(t,$CLJS.tF)],null)],null)],null)}throw $CLJS.Z;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw u;}else throw $CLJS.Z;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)return $CLJS.yU(k,e,f);throw l;}throw u;}}($CLJS.xf,b)))),$CLJS.uW.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JE,$CLJS.VD],null),$CLJS.kk.g($CLJS.yf,$CLJS.Qe($CLJS.lk,b)))):a};
ina=function(a){try{return hna(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.Th($CLJS.MD("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.JE,a],null),b);}throw c;}};
jna=function(a,b){var c=$CLJS.Wf.g($CLJS.fd(a),function(){return function f(e){return new $CLJS.ie(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var A=0;;)if(A<m){var C=$CLJS.hd(l,A),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var K=$CLJS.Yd.g(b,G);C=vW.g?vW.g(C,K):vW.call(null,C,K);null!=C&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[G,C],null));A+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),
null)}var u=$CLJS.z(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var A=x,C=$CLJS.Yd.g(b,v);return vW.g?vW.g(A,C):vW.call(null,A,C)}();if(null!=u)return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Hc(k)));k=$CLJS.Hc(k)}else return null},null,null)}(a)}());return $CLJS.y(c)?c:null};kna=function(a,b){a=$CLJS.mk.g(function(c){var d=$CLJS.Yd.g(b,wW);return vW.g?vW.g(c,d):vW.call(null,c,d)},a);return $CLJS.n($CLJS.Me($CLJS.Sa,a))?a:null};
PV=function PV(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.yf(d);return $CLJS.ae(f,new $CLJS.ie(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),PV.g?PV.g(a,k):PV.call(null,a,k)):null},null,null))};
$CLJS.uW=function uW(a){switch(arguments.length){case 3:return uW.j(arguments[0],arguments[1],arguments[2]);case 4:return uW.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return uW.M(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return uW.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return uW.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.w(c.slice(6),0,null))}};$CLJS.uW.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.z(d);if(d=$CLJS.B(d))a=$CLJS.R.j(a,b,$CLJS.uW.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.uW.v=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.z(e);if(e=$CLJS.B(e))a=$CLJS.R.j(a,b,$CLJS.uW.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.uW.M=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.z(f);if(f=$CLJS.B(f))a=$CLJS.R.j(a,b,$CLJS.uW.M($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.uW.W=function(a,b,c,d,e,f){var k=$CLJS.y(b);b=$CLJS.z(k);if(k=$CLJS.B(k))a=$CLJS.R.j(a,b,$CLJS.uW.W($CLJS.J.g(a,b),k,c,d,e,f));else{k=$CLJS.R.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.R,a,b,c)}return a};$CLJS.uW.l=function(a,b,c,d,e,f,k){var l=$CLJS.y(b);b=$CLJS.z(l);return(l=$CLJS.B(l))?$CLJS.R.j(a,b,$CLJS.Ne.l($CLJS.uW,$CLJS.J.g(a,b),l,c,d,$CLJS.H([e,f,k]))):$CLJS.R.j(a,b,$CLJS.Ne.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.H([k])))};
$CLJS.uW.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);var e=$CLJS.B(d);d=$CLJS.z(e);var f=$CLJS.B(e);e=$CLJS.z(f);var k=$CLJS.B(f);f=$CLJS.z(k);k=$CLJS.B(k);return this.l(b,a,c,d,e,f,k)};$CLJS.uW.A=6;
var ZV=function ZV(a){for(;;){if($CLJS.sd(a))return $CLJS.hs(a,ZV);if($CLJS.yd(a))a=$CLJS.yf(a);else if($CLJS.ud(a)){if($CLJS.Ra($CLJS.Me($CLJS.Sa,a)))return null;var c=a,d=$CLJS.y(c),e=$CLJS.z(d),f=$CLJS.B(d),k=e,l=f,m=$CLJS.I(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.S:null;switch(v){case "not":if($CLJS.ll(m)){var x=$CLJS.z(m),A=x instanceof $CLJS.M?x.S:null;switch(A){case "not":a=$CLJS.dd(m);continue;case "and":return $V($CLJS.gr,$CLJS.cf.g(function(){return function(C){return new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ir,C],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Hc(m)));case "or":return $V($CLJS.mr,$CLJS.cf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ir,C],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Hc(m)));default:return a}}else return a;case "and":return $V($CLJS.mr,t);case "or":return $V($CLJS.gr,t);default:return $CLJS.mk.g(ZV,a)}}else return a}},lna=new $CLJS.M(null,"value_field","value_field",-980977878),xW=new $CLJS.M(null,"ascending","ascending",-988350486),
yW=new $CLJS.M(null,"named","named",-422393479),zW=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),AW=new $CLJS.M(null,"descending","descending",-24766135),BW=new $CLJS.M(null,"datetime-field","datetime-field",21731696),mna=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),CW=new $CLJS.M(null,"field-literal","field-literal",-1295883554),DW=new $CLJS.M(null,"viz-settings","viz-settings",256055379),nna=new $CLJS.M(null,"rows","rows",850049680),ona=
new $CLJS.M(null,"special-fn","special-fn",1290649344),wW=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),dW=new $CLJS.M(null,"ignore-path","ignore-path",944069061),pna=new $CLJS.M(null,"label_field","label_field",-1573182765),qna=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),rna=new $CLJS.M(null,"joined-field","joined-field",-2048778268),bna=new $CLJS.M(null,"visibility_type","visibility_type",-508434247);var EW,sna=$CLJS.Se($CLJS.N),tna=$CLJS.Se($CLJS.N),una=$CLJS.Se($CLJS.N),vna=$CLJS.Se($CLJS.N),wna=$CLJS.J.j($CLJS.N,$CLJS.Si,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));EW=new $CLJS.Ph($CLJS.qh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.kk.g(SV,$CLJS.z),wna,sna,tna,una,vna);EW.m(null,$CLJS.cF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cF,b],null);return null!=a?$CLJS.Yd.g(b,a):b});
EW.m(null,$CLJS.qA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qA,b instanceof $CLJS.M?XV(b):b],null);a=$CLJS.Be($CLJS.UV(new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.tA,null,$CLJS.tF,null,$CLJS.sQ,null],null),null),eW(a)));return $CLJS.n(a)?$CLJS.Yd.g(b,a):b});
EW.m(null,$CLJS.AU,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.Yd.g(EW.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AU,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AU,cW.g?cW.g(b,dW):cW.call(null,b,dW),SV(c)],null)});EW.m(null,$CLJS.nF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,b,eW(a)],null)});
EW.m(null,CW,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[CW,b instanceof $CLJS.M?XV(b):b,$CLJS.mh.h(a)],null)});EW.m(null,BW,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[BW,cW.g?cW.g(b,dW):cW.call(null,b,dW),$CLJS.Yr,SV(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[BW,cW.g?cW.g(b,dW):cW.call(null,b,dW),SV(c)],null)});
EW.m(null,$CLJS.dB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.Yd.g(EW.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dB,b,c,d],null)),cW.g?cW.g(a,dW):cW.call(null,a,dW)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dB,cW.g?cW.g(b,dW):cW.call(null,b,dW),$CLJS.Ad(c)?c:SV(c),SV(d)],null)});
EW.m(null,$CLJS.$A,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$A,b,SV(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$A,$CLJS.Qu],null)});EW.m(null,$CLJS.ZA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZA,b,SV(a)],null)});
EW.m(null,$CLJS.bB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.bB,cW.g?cW.g(b,dW):cW.call(null,b,dW),c,SV(a)],null)});EW.m(null,$CLJS.eB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.eB,cW.g?cW.g(b,dW):cW.call(null,b,dW),c,SV(a)],null)});
EW.m(null,$CLJS.aB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aB,cW.g?cW.g(b,dW):cW.call(null,b,dW),SV(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aB,cW.g?cW.g(b,dW):cW.call(null,b,dW)],null)});
EW.m(null,$CLJS.cB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.cB,cW.g?cW.g(b,dW):cW.call(null,b,dW),SV(c),SV(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cB,cW.g?cW.g(b,dW):cW.call(null,b,dW),SV(c)],null)});
EW.m(null,$CLJS.YA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.YA,cW.g?cW.g(b,dW):cW.call(null,b,dW),cW.g?cW.g(c,dW):cW.call(null,c,dW),SV(a)],null)});EW.m(null,$CLJS.hj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hj,b,a],null)});
EW.m(null,$CLJS.Oh,function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);return $CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[SV(a)],null),$CLJS.cf.h(function(c){return cW.g?cW.g(c,dW):cW.call(null,c,dW)}),b)});
var FW=function FW(a){if($CLJS.n($CLJS.zz($CLJS.be,$CLJS.Wa)(a))){var c=SV(a);var d=new $CLJS.Rg(null,new $CLJS.h(null,18,[$CLJS.ti,null,$CLJS.Mq,null,$CLJS.BF,null,$CLJS.wF,null,$CLJS.$r,null,$CLJS.oF,null,$CLJS.GF,null,$CLJS.HF,null,$CLJS.SE,null,$CLJS.ZE,null,$CLJS.Lq,null,$CLJS.TE,null,$CLJS.AF,null,$CLJS.uF,null,$CLJS.Fj,null,$CLJS.Rw,null,$CLJS.hF,null,$CLJS.zF,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(aW(a))?(a=$CLJS.z(a),FW.h?FW.h(a):FW.call(null,
a)):null},xna=new $CLJS.h(null,8,[$CLJS.Vi,SV,$CLJS.hP,function(a){a=TV(a);return $CLJS.y($CLJS.EP.h(a))?$CLJS.nk.j(a,$CLJS.EP,$ma):a},$CLJS.JE,new $CLJS.h(null,6,[$CLJS.cF,function GW(a){if($CLJS.n($CLJS.zz($CLJS.be,$CLJS.Wa)(a)))return SV(a);if($CLJS.n(bW(yW,a))){a=$CLJS.y(a);$CLJS.z(a);var c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[yW,GW.h?GW.h(a):GW.call(null,a)],null),c)}return $CLJS.n($CLJS.n(aW(a))?FW($CLJS.dd(a)):null)?$CLJS.mk.g(GW,a):cW.g?cW.g(a,
dW):cW.call(null,a,dW)},$CLJS.YO,function(a){return $CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.vd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[XV(u),cW.g?cW.g(t,dW):cW.call(null,t,dW)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}f=
$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[XV(l),cW.g?cW.g(f,dW):cW.call(null,f,dW)],null),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())},$CLJS.MQ,function(a){return $CLJS.yf(function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.vd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m);t=$CLJS.n(aW(t))?EW.h(t):$CLJS.Zd(EW.h($CLJS.Zd(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}l=$CLJS.z(e);return $CLJS.ae($CLJS.n(aW(l))?EW.h(l):$CLJS.Zd(EW.h($CLJS.Zd(l))),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())},$CLJS.GP,function(a){a=TV(a);a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.hP);if($CLJS.n(b))return a=$CLJS.xU(a,new $CLJS.h(null,1,[$CLJS.hP,$CLJS.JE],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.hP],null),a=cW.g?cW.g(a,b):cW.call(null,a,b),$CLJS.xU(a,new $CLJS.h(null,1,[$CLJS.JE,$CLJS.hP],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.JE],null);return cW.g?cW.g(a,b):cW.call(null,a,b)},$CLJS.QN,new $CLJS.h(null,1,[wW,gW],null),$CLJS.CM,new $CLJS.h(null,1,[wW,function(a){a=cW.g?cW.g(a,$CLJS.JE):cW.call(null,a,$CLJS.JE);var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.FD),d=$CLJS.J.g(b,$CLJS.VD);a=$CLJS.J.g(b,$CLJS.ZD);b=$CLJS.n(c)?$CLJS.nk.j(b,$CLJS.FD,SV):b;d=$CLJS.n($CLJS.zz($CLJS.be,$CLJS.Wa)(d))?$CLJS.nk.j(b,$CLJS.VD,SV):b;return $CLJS.n(a)?$CLJS.nk.j(d,$CLJS.ZD,XV):d}],null)],null),$CLJS.Gx,
new $CLJS.h(null,1,[$CLJS.nN,$CLJS.Pd],null),$CLJS.tM,new $CLJS.h(null,1,[wW,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Vi),d=$CLJS.J.g(b,$CLJS.pI),e=$CLJS.J.g(b,$CLJS.Ki);a=$CLJS.J.g(b,zW);b=$CLJS.n(e)?$CLJS.nk.j(b,$CLJS.Ki,XV):b;c=$CLJS.n(c)?$CLJS.nk.j(b,$CLJS.Vi,SV):b;d=$CLJS.n(d)?$CLJS.nk.j(c,$CLJS.pI,function(f){return cW.g?cW.g(f,dW):cW.call(null,f,dW)}):c;d=$CLJS.n(a)?$CLJS.uW.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[zW,pna],null),function(f){return cW.g?cW.g(f,dW):cW.call(null,f,dW)}):d;return $CLJS.n(a)?
$CLJS.uW.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[zW,lna],null),function(f){return cW.g?cW.g(f,dW):cW.call(null,f,dW)}):d}],null),$CLJS.oM,function(a){return null==a?null:SV(a)},$CLJS.QN,new $CLJS.h(null,1,[wW,gW],null),DW,SV],null),cW=function cW(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return cW.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
cW.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.yf(b);b=$CLJS.y(c)?$CLJS.Bz(xna,c):null;try{return $CLJS.id(b)?b.h?b.h(a):b.call(null,a):$CLJS.td(a)?a:$CLJS.sd(a)?$CLJS.Wf.g($CLJS.N,function(){return function k(f){return new $CLJS.ie(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.vd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v),A=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);A=SV(A);A=new $CLJS.P(null,
2,5,$CLJS.Q,[A,cW.l(x,$CLJS.H([$CLJS.Yd.g($CLJS.yf(c),A)]))],null);u.add(A);v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}m=$CLJS.z(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=SV(u);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[u,cW.l(m,$CLJS.H([$CLJS.Yd.g($CLJS.yf(c),u)]))],null),k($CLJS.Hc(l)))}return null}},null,null)}(a)}()):$CLJS.n(aW(a))?EW.h(a):$CLJS.rd(a)?$CLJS.mk.g(function(e){return cW.l(e,$CLJS.H([$CLJS.Yd.g($CLJS.yf(c),wW)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.Th($CLJS.hD("Error normalizing form: {0}",$CLJS.H([$CLJS.rU(d)])),new $CLJS.h(null,3,[$CLJS.Fi,a,$CLJS.Qk,c,ona,b],null),d);}throw e;}};cW.A=1;cW.B=function(a){var b=$CLJS.z(a);a=$CLJS.B(a);return this.l(b,a)};
var iW=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.Si,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(aW(f))?$CLJS.z(f):null},e,a,b,c,d)}();iW.m(null,$CLJS.Oh,function(a){return a});
iW.m(null,$CLJS.nF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(bW($CLJS.nF,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return iW.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,c,$CLJS.Be($CLJS.Ck.l($CLJS.H([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,b,$CLJS.Be(a)],null)});
iW.m(null,$CLJS.cF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.od(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cF,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cF,b,a],null)});iW.m(null,$CLJS.VJ,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(aW(a))?iW.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,a,null],null)});
iW.m(null,CW,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,b,new $CLJS.h(null,1,[$CLJS.tA,a],null)],null)});iW.m(null,qna,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=jW(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=jW(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,a,$CLJS.R.j(c,$CLJS.DM,b)],null)});
iW.m(null,rna,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=jW(a);return $CLJS.Ne.v($CLJS.FU,a,$CLJS.R,$CLJS.H([$CLJS.sO,b]))});
iW.m(null,BW,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=jW(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.tA);$CLJS.Ra(d)||$CLJS.IR.g(d,b)?a=$CLJS.Ne.v($CLJS.FU,a,$CLJS.R,$CLJS.H([$CLJS.tF,b])):(c=$CLJS.Mz($CLJS.Ex),$CLJS.n($CLJS.Lz("metabase.mbql.util",c))&&(b=$CLJS.hD("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.H([b,d,$CLJS.xh.l($CLJS.H([a]))])),
b instanceof Error?$CLJS.Kz("metabase.mbql.util",c,$CLJS.xv(),b):$CLJS.Kz("metabase.mbql.util",c,$CLJS.xv.l($CLJS.H([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),iW.h(new $CLJS.P(null,3,5,$CLJS.Q,[BW,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
iW.m(null,$CLJS.AU,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=jW(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,b,$CLJS.R.j(e,$CLJS.sQ,$CLJS.Ck.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.FD,c],null),$CLJS.n(d)?$CLJS.Ae([c,d]):null,a])))],null)});
for(var HW=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,$CLJS.gr,$CLJS.ir],null)),IW=null,JW=0,KW=0;;)if(KW<JW){var LW=IW.X(null,KW);iW.m(null,LW,function(){return function(a){return kW(a)}}(HW,IW,JW,KW,LW));KW+=1}else{var MW=$CLJS.y(HW);if(MW){var NW=MW;if($CLJS.vd(NW)){var OW=$CLJS.ic(NW),yna=$CLJS.jc(NW),zna=OW,Ana=$CLJS.D(OW);HW=yna;IW=zna;JW=Ana}else{var PW=$CLJS.z(NW);iW.m(null,PW,function(){return function(a){return kW(a)}}(HW,IW,JW,KW,PW,NW,MW));HW=$CLJS.B(NW);IW=null;JW=0}KW=0}else break}
iW.m(null,$CLJS.VE,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VE,jW(a),jW(b)],null),c)});iW.m(null,$CLJS.dB,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);b=$CLJS.B(b);var c=jW(a);a=$CLJS.n(YV($CLJS.nF,a))?$CLJS.FU.l(c,$CLJS.dk,$CLJS.H([$CLJS.tF])):c;return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dB,a],null),b)});
for(var QW=$CLJS.y(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.eF,$CLJS.yF,$CLJS.mF,$CLJS.fF,$CLJS.Jq,$CLJS.DF,$CLJS.Gq,$CLJS.Iq,$CLJS.Cq,$CLJS.Eq,$CLJS.QE,$CLJS.XE,$CLJS.OE,$CLJS.$E,$CLJS.PE],null)),RW=null,SW=0,TW=0;;)if(TW<SW){var UW=RW.X(null,TW);iW.m(null,UW,function(){return function(a){return lW(a)}}(QW,RW,SW,TW,UW));TW+=1}else{var VW=$CLJS.y(QW);if(VW){var WW=VW;if($CLJS.vd(WW)){var XW=$CLJS.ic(WW),Bna=$CLJS.jc(WW),Cna=XW,Dna=$CLJS.D(XW);QW=Bna;RW=Cna;SW=Dna}else{var YW=$CLJS.z(WW);iW.m(null,YW,
function(){return function(a){return lW(a)}}(QW,RW,SW,TW,YW,WW,VW));QW=$CLJS.B(WW);RW=null;SW=0}TW=0}else break}iW.m(null,nna,function(){return null});iW.m(null,$CLJS.tL,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tL,iW.h(b),a],null)});
iW.m(null,yW,function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=iW.h;var e=$CLJS.Q;b=iW.h(b);d=$CLJS.I(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.J.g(d,mna)?new $CLJS.h(null,1,[$CLJS.U,a],null):new $CLJS.h(null,1,[$CLJS.oD,a],null);return c.call(iW,new $CLJS.P(null,3,5,e,[$CLJS.tL,b,a],null))});
for(var ZW=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rw,$CLJS.oF],null)),$W=null,aX=0,bX=0;;)if(bX<aX){var cX=$W.X(null,bX);iW.m(null,cX,function(){return function(a){return mW(a)}}(ZW,$W,aX,bX,cX));bX+=1}else{var dX=$CLJS.y(ZW);if(dX){var eX=dX;if($CLJS.vd(eX)){var fX=$CLJS.ic(eX),Ena=$CLJS.jc(eX),Fna=fX,Gna=$CLJS.D(fX);ZW=Ena;$W=Fna;aX=Gna}else{var gX=$CLJS.z(eX);iW.m(null,gX,function(){return function(a){return mW(a)}}(ZW,$W,aX,bX,gX,eX,dX));ZW=$CLJS.B(eX);$W=null;aX=0}bX=0}else break}
for(var hX=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.hF,$CLJS.IF,$CLJS.SE,$CLJS.BF,$CLJS.zF,$CLJS.ti,$CLJS.Fj,$CLJS.AF,$CLJS.TE],null)),iX=null,jX=0,kX=0;;)if(kX<jX){var lX=iX.X(null,kX);iW.m(null,lX,function(){return function(a){return nW(a)}}(hX,iX,jX,kX,lX));kX+=1}else{var mX=$CLJS.y(hX);if(mX){var nX=mX;if($CLJS.vd(nX)){var oX=$CLJS.ic(nX),Hna=$CLJS.jc(nX),Ina=oX,Jna=$CLJS.D(oX);hX=Hna;iX=Ina;jX=Jna}else{var pX=$CLJS.z(nX);iW.m(null,pX,function(){return function(a){return nW(a)}}(hX,iX,jX,kX,
pX,nX,mX));hX=$CLJS.B(nX);iX=null;jX=0}kX=0}else break}iW.m(null,$CLJS.ZE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZE,jW(b),a],null)});
for(var qX=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uF,$CLJS.wF],null)),rX=null,sX=0,tX=0;;)if(tX<sX){var uX=rX.X(null,tX);iW.m(null,uX,function(){return function(a){return oW(a)}}(qX,rX,sX,tX,uX));tX+=1}else{var vX=$CLJS.y(qX);if(vX){var wX=vX;if($CLJS.vd(wX)){var xX=$CLJS.ic(wX),Kna=$CLJS.jc(wX),Lna=xX,Mna=$CLJS.D(xX);qX=Kna;rX=Lna;sX=Mna}else{var yX=$CLJS.z(wX);iW.m(null,yX,function(){return function(a){return oW(a)}}(qX,rX,sX,tX,yX,wX,vX));qX=$CLJS.B(wX);rX=null;sX=0}tX=0}else break}
iW.m(null,$CLJS.GF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GF,iW.h(b),iW.h(a)],null)});
iW.m(null,$CLJS.WE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.Yd.g(iW.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WE,b],null)),cW.l(a,$CLJS.H([dW]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WE,$CLJS.yf(function(){return function e(d){return new $CLJS.ie(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.vd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);a:for(var t=0;;)if(t<l){var u=$CLJS.hd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[iW.h(v),iW.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}k=$CLJS.z(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[iW.h(m),iW.h(k)],null),e($CLJS.Hc(f)))}return null}},null,null)}(b)}())],null)});
iW.m(null,$CLJS.FE,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FE,iW.h(a),$CLJS.E.g(0,b)?1:iW.h(b)],null),$CLJS.cf.g(iW,c))});
var fW=function fW(a){if($CLJS.td(a))return $CLJS.Od(function(d,e,f){return $CLJS.R.j(d,e,fW.h?fW.h(f):fW.call(null,f))},a,a);if($CLJS.sd(a))return $CLJS.hs(a,fW);if($CLJS.n(aW(a))){var c=function(){try{return iW.h(a)}catch(k){if(k instanceof Error){var d=k,e=$CLJS.Mz($CLJS.Cx);if($CLJS.n($CLJS.Lz("metabase.mbql.normalize",e))){var f=$CLJS.MD("Invalid clause:");f instanceof Error?$CLJS.Kz("metabase.mbql.normalize",e,$CLJS.xv.l($CLJS.H([a])),f):$CLJS.Kz("metabase.mbql.normalize",e,$CLJS.xv.l($CLJS.H([f,
a])),null)}throw $CLJS.Th($CLJS.hD("Invalid MBQL clause: {0}",$CLJS.H([$CLJS.rU(d)])),new $CLJS.h(null,1,[$CLJS.XQ,a],null),d);}throw k;}}();return $CLJS.y(c)?$CLJS.Wf.j($CLJS.Yd.g($CLJS.fd(c),$CLJS.z(c)),$CLJS.cf.h(fW),$CLJS.Hc(c)):c}return $CLJS.yd(a)?$CLJS.mk.g(fW,a):$CLJS.pd(a)?$CLJS.Wf.j($CLJS.fd(a),$CLJS.cf.h(fW),a):a},pW=function pW(a){return function f(d,e){try{if($CLJS.n(function(){var x=VV($CLJS.yd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.yf(e));throw $CLJS.Z;}catch(x){if(x instanceof
Error)if(d=x,d===$CLJS.Z)try{if($CLJS.n(function(){var A=VV($CLJS.be);return A.h?A.h(e):A.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Z;}catch(A){if(A instanceof Error)if(d=A,d===$CLJS.Z)try{if($CLJS.ud(e)&&2<=$CLJS.D(e))try{var k=$CLJS.rk.j(e,0,2);if($CLJS.ud(k)&&2===$CLJS.D(k))try{var l=$CLJS.F(k,0);if($CLJS.n($CLJS.fD($CLJS.be,$CLJS.Oe(new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.Mq,null,$CLJS.$r,null,$CLJS.HF,null,$CLJS.Lq,null,
yW,null],null),null)))(l)))try{var m=$CLJS.F(k,1);if($CLJS.n(FW(m)))return $CLJS.Wf.j($CLJS.xf,$CLJS.gD(pW),a);throw $CLJS.Z;}catch(C){if(C instanceof Error){var t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error)if(t=C,t===$CLJS.Z)try{if($CLJS.ud(e)&&
1<=$CLJS.D(e))try{var u=$CLJS.rk.j(e,0,1);if($CLJS.ud(u)&&1===$CLJS.D(u))try{if($CLJS.F(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Z;}catch(G){if(G instanceof Error){var v=G;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Z)return e;throw v;}throw G;}else throw t;else throw C;}else throw d;else throw A;
}else throw d;else throw x;}}($CLJS.xf,a)},zX=function zX(a){return function f(d,e){try{var k=VV($CLJS.yd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.yf(e));throw $CLJS.Z;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Z)try{if($CLJS.ud(e)&&2===$CLJS.D(e))try{var m=$CLJS.F(e,1);if($CLJS.ce(m,$CLJS.kF)){var t=$CLJS.F(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kF,t],null))}throw $CLJS.Z;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.ce(m,
$CLJS.dF))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dF,t],null));throw $CLJS.Z;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.ce(m,xW))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kF,t],null));throw $CLJS.Z;}catch(S){if(S instanceof Error)if(l=S,l===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.ce(m,AW))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dF,t],null));throw $CLJS.Z;}catch(X){if(X instanceof Error)if(m=X,m===
$CLJS.Z)try{var v=$CLJS.F(e,0);if($CLJS.ce(v,xW))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kF,t],null));throw $CLJS.Z;}catch(T){if(T instanceof Error)if(m=T,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.ce(v,AW))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dF,t],null));throw $CLJS.Z;}catch(da){if(da instanceof Error)if(m=da,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.ce(v,$CLJS.kF))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kF,jW(t)],null);throw $CLJS.Z;}catch(oa){if(oa instanceof
Error)if(m=oa,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.ce(v,$CLJS.dF))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dF,jW(t)],null);throw $CLJS.Z;}catch(Ma){if(Ma instanceof Error){t=Ma;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw Ma;}else throw m;else throw oa;}else throw m;else throw da;}else throw m;else throw T;}else throw m;else throw X;}else throw l;else throw S;}else throw u;}else throw K;}else throw l;else throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Z)try{if($CLJS.ud(e)&&
0<=$CLJS.D(e))try{var x=$CLJS.rk.j(e,0,0);if($CLJS.ud(x)&&0===$CLJS.D(x))try{var A=$CLJS.rk.g(e,0);if($CLJS.E.g(A,a))return $CLJS.yf($CLJS.Dk.h($CLJS.cf.g(zX,a)));throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw K;}else throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw K;}else throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)return $CLJS.WV(f,d,e);throw u;}throw K;}else throw l;else throw G;
}else throw l;else throw C;}}($CLJS.xf,a)},sW=$CLJS.kk.g(fW,function(a){var b=tW($CLJS.cF.h(a))?$CLJS.nk.j(a,$CLJS.cF,cna):a;b=tW($CLJS.bF.h(a))?$CLJS.nk.j(b,$CLJS.bF,dna):b;b=tW($CLJS.VD.h(a))?$CLJS.nk.j(b,$CLJS.VD,$CLJS.Qe($CLJS.mk,hW)):b;b=tW($CLJS.MQ.h(a))?$CLJS.nk.j(b,$CLJS.MQ,zX):b;return tW($CLJS.GP.h(a))?$CLJS.nk.j(b,$CLJS.GP,fna):b}),Nna=new $CLJS.h(null,3,[$CLJS.hP,$CLJS.Pd,$CLJS.JE,new $CLJS.h(null,2,[$CLJS.GP,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.hP);if($CLJS.n(b))return a=
$CLJS.xU(a,new $CLJS.h(null,1,[$CLJS.hP,$CLJS.JE],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.hP],null),a=vW.g?vW.g(a,b):vW.call(null,a,b),$CLJS.xU(a,new $CLJS.h(null,1,[$CLJS.JE,$CLJS.hP],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.JE],null);return vW.g?vW.g(a,b):vW.call(null,a,b)},$CLJS.CM,new $CLJS.h(null,1,[wW,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.JE],null);return vW.g?vW.g(a,b):vW.call(null,a,b)}],null)],null),DW,$CLJS.Pd],null),vW=function vW(a){switch(arguments.length){case 1:return vW.h(arguments[0]);
case 2:return vW.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};vW.h=function(a){return vW.g(a,$CLJS.xf)};vW.g=function(a,b){try{var c=$CLJS.y(b)?$CLJS.Bz(Nna,b):null;return $CLJS.id(c)?c.h?c.h(a):c.call(null,a):$CLJS.td(a)?a:$CLJS.sd(a)?jna(a,b):$CLJS.rd(a)?kna(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.Th("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.Fi,a,$CLJS.Qk,b],null),d);throw d;}};vW.A=2;
$CLJS.AX=function(){var a=$CLJS.kk.l(vW,ina,gna,$CLJS.H([cW]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.Th($CLJS.hD("Error normalizing query: {0}",$CLJS.H([$CLJS.rU(c)])),new $CLJS.h(null,1,[$CLJS.JE,b],null),c);}throw d;}}}();$CLJS.BX=function BX(a,b){if($CLJS.Ra($CLJS.y(a)))a=(0,$CLJS.AX)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.fs(a);b=$CLJS.Ae([$CLJS.ed(a),b]);f=BX.g?BX.g(f,b):BX.call(null,f,b);a=e.call(d,f,$CLJS.ed(a))}return a};