var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.util.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var rY,zoa,Aoa,Boa,Coa,yY,Doa,Eoa,zY,Foa,BY,Goa,Hoa,Ioa,GY,HY,Joa,Koa,PY,Loa,Moa,Noa,UY,Ooa;rY=function(){};$CLJS.sY=function(a){return null!=a?$CLJS.yc===a.ni?!0:a.Wc?!1:$CLJS.Ya(rY,a):$CLJS.Ya(rY,a)};zoa=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.tY=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Aoa=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Boa=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.uY=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.vY=new $CLJS.M(null,"database-id","database-id",1883826326);Coa=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.wY=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);$CLJS.xY=new $CLJS.M(null,"search","search",1564939822);yY=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Doa=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Eoa=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
zY=new $CLJS.M("metabase.lib.schema.metadata","column.has-field-values","metabase.lib.schema.metadata/column.has-field-values",-1383672167);Foa=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.AY=new $CLJS.M(null,"dataset","dataset",1159262238);BY=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Goa=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);
$CLJS.CY=new $CLJS.M("source","expressions","source/expressions",-458367840);$CLJS.DY=new $CLJS.M(null,"definition","definition",-1198729982);Hoa=new $CLJS.M(null,"state","state",-1988618099);$CLJS.EY=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);Ioa=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.FY=new $CLJS.M("source","joins","source/joins",1225821486);
GY=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);HY=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.IY=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.JY=new $CLJS.M(null,"selected?","selected?",-742502788);Joa=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);
Koa=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.KY=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.LY=new $CLJS.M("source","card","source/card",-139977973);$CLJS.MY=new $CLJS.M(null,"active","active",1895962068);$CLJS.NY=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);$CLJS.OY=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);
PY=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Loa=new $CLJS.M(null,"details","details",1956795411);$CLJS.QY=new $CLJS.M("source","fields","source/fields",-649667981);$CLJS.RY=new $CLJS.M(null,"has-field-values","has-field-values",626648377);Moa=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.SY=new $CLJS.M("source","native","source/native",-1444604147);
Noa=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);$CLJS.TY=new $CLJS.M(null,"auto-list","auto-list",314197731);UY=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);$CLJS.VY=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);Ooa=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);
$CLJS.WY=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.XY=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.Y(GY,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.dr,$CLJS.LY,$CLJS.SY,$CLJS.uY,$CLJS.wY,$CLJS.QY,$CLJS.kK,$CLJS.cK,$CLJS.FY,$CLJS.CY,$CLJS.EY],null));$CLJS.Y(zY,$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.dr],null),$CLJS.Cz($CLJS.Dd,new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.TY,null,$CLJS.xY,null,$CLJS.gj,null,$CLJS.cz,null],null),null))));
$CLJS.Y(PY,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ij,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jq,$CLJS.jK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,$CLJS.UJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.tD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VJ,$CLJS.CD],null)],null));
$CLJS.Y(yY,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ij,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jq,$CLJS.eK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,$CLJS.UJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.tD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,$CLJS.Zh],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,$CLJS.Zh],null)],null)],null));
$CLJS.Y($CLJS.TJ,new $CLJS.P(null,20,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.h(null,1,[$CLJS.us,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ij,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jq,$CLJS.MJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.zj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.DD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ki,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.CD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.fr,$CLJS.zj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pi,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.DD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$J,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.CD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YJ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.CD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WY,
new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.tD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.tD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NJ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GY],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VY,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.WJ],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tY,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.tD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IY,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,2,[$CLJS.ti,1,$CLJS.Fj,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JY,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.vr],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.RY,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZJ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fK,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yY],null)],null)],
null)],null));$CLJS.Y(UY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KY,$CLJS.tD],null),new $CLJS.P(null,2,5,$CLJS.Q,[Goa,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[Foa,$CLJS.tD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.DD],null)],null)],null)],null)],null)],null));
$CLJS.Y(BY,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MY,$CLJS.vr],null),new $CLJS.P(null,2,5,$CLJS.Q,[Hoa,$CLJS.tD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KY,$CLJS.tD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DY,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LJ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,
$CLJS.tD],null)],null)],null));
$CLJS.Y(Moa,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.h(null,1,[$CLJS.us,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ij,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jq,$CLJS.iK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,$CLJS.WJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.tD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vY,$CLJS.XJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NY,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.Ej],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OY,
new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,$CLJS.Ej],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AY,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.vr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aK,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.PJ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Boa,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,BY],null)],null)],null)],null));
$CLJS.Y(Joa,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.h(null,1,[$CLJS.us,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ij,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jq,$CLJS.OJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,$CLJS.lD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.tD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aK,$CLJS.PJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.Ej],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Is,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.tD],null)],null)],null));
$CLJS.Y(Koa,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.h(null,1,[$CLJS.us,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ij,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jq,$CLJS.QJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,$CLJS.jD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.tD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aK,$CLJS.PJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.Ej],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Is,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.tD],null)],null)],null));
$CLJS.Y(Noa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.h(null,1,[$CLJS.us,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ij,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jq,$CLJS.SJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,$CLJS.PJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.tD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.tD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rk,new $CLJS.h(null,
1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.tD],null)],null)],null));
$CLJS.Y(Aoa,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.h(null,1,[$CLJS.us,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ij,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jq,$CLJS.gK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,$CLJS.XJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[Eoa,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.Ej],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Loa,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.Ej],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.XY,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.Ii],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dz,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Li,$CLJS.Ii],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Ioa,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.vr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bK,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.Ej],null)],null)],null));
$CLJS.Y(HY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.us,"Valid MetadataProvider"],null),new $CLJS.Cc(function(){return $CLJS.sY},Coa,$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Kj,$CLJS.gi,$CLJS.Ci,$CLJS.Wi,$CLJS.Uj,$CLJS.fi,$CLJS.Gj,$CLJS.Jj],[zoa,Ooa,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.V(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Hp],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.sY)?$CLJS.sY.H:null]))],null));
$CLJS.Y(Doa,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.h(null,1,[$CLJS.us,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HY],null)],null)],null)],null));