var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var BK,DK,FK,IK,LK;$CLJS.zK=function(a){return $CLJS.Hh($CLJS.q($CLJS.gB),a,$CLJS.rF)};$CLJS.AK=new $CLJS.M("lib","source-name","lib/source-name",1931622490);BK=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.CK=new $CLJS.M(null,"operators","operators",-2064102509);DK=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);
$CLJS.EK=new $CLJS.M(null,"stage-number","stage-number",-1752729638);FK=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.GK=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.HK=new $CLJS.M(null,"dimensions","dimensions",-254818097);IK=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.JK=new $CLJS.M(null,"max-value","max-value",687805168);
$CLJS.KK=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);LK=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.MK=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.Y(DK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,$CLJS.yD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tF,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.eD],null)],null)],null));$CLJS.Y(LK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,DK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.DD],null)],null)],null));$CLJS.Y(FK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jq,$CLJS.nF],null),LK,$CLJS.tD],null));
$CLJS.Y(IK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jq,$CLJS.nF],null),DK,$CLJS.CD],null));
$CLJS.MF.g($CLJS.nF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jq,$CLJS.nF],null),DK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,$CLJS.CD,$CLJS.tD],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,2,[$CLJS.yi,function(a){return $CLJS.rd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.XA(a)):null},$CLJS.us,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.lA,IK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kA,FK],null)],null)],null));$CLJS.tE($CLJS.nF,$CLJS.rF);$CLJS.OD.m(null,$CLJS.nF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.pi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.bE});$CLJS.AE($CLJS.qA,$CLJS.H([$CLJS.tD]));$CLJS.OD.m(null,$CLJS.qA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.pi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.bE});
$CLJS.tE($CLJS.qA,$CLJS.rF);$CLJS.Y(BK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,$CLJS.yD,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.tD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.tD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AK,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.tD],null)],null)],null));
$CLJS.MF.g($CLJS.cF,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jq,$CLJS.cF],null),BK,$CLJS.zj],null));$CLJS.OD.m(null,$CLJS.cF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.pi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.bE});$CLJS.tE($CLJS.cF,$CLJS.rF);$CLJS.AE($CLJS.LE,$CLJS.H([$CLJS.$r,$CLJS.qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lD],null)],null)]));$CLJS.tE($CLJS.LE,$CLJS.rF);
$CLJS.AE($CLJS.iF,$CLJS.H([$CLJS.$r,$CLJS.bE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jD],null)],null)]));$CLJS.tE($CLJS.iF,$CLJS.rF);
$CLJS.Y($CLJS.rF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,$CLJS.QD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.Fs,function(){return["Valid reference, must be one of these clauses: ",$CLJS.is(", ",$CLJS.Cz($CLJS.Dd,$CLJS.Gk.g($CLJS.q($CLJS.gB),$CLJS.rF)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.zK(a)}],null)],null));