var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var bU,cU,dU,fU,gU,hU,iU,jU,kU,lU;bU=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);cU=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);dU=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.eU=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);fU=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);gU=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);hU=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
iU=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);jU=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);kU=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);lU=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.Y(bU,$CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.us,"Valid template tag :widget-type"],null),$CLJS.cz],null),$CLJS.Ng($CLJS.XT)));$CLJS.Y(fU,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.dr,$CLJS.FL,$CLJS.uM,$CLJS.GE,$CLJS.Qs,$CLJS.Lj,$CLJS.AO],null));
$CLJS.Y(iU,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.tD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oD,$CLJS.tD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ki,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,$CLJS.tD,$CLJS.ur],null)],null)],null));
$CLJS.Y(jU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.cl],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WM,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.vr],null)],null)],null));
$CLJS.Y(gU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jU],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jq,$CLJS.GE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.Br,!0],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.Ej],null)],null)],null)],null));
$CLJS.Y(hU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iU],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jq,$CLJS.FL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rL,$CLJS.tD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SO,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.hK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TO,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.XJ],null)],null)],null));
$CLJS.Y(lU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jq,$CLJS.uM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aQ,$CLJS.WJ],null)],null)],null));$CLJS.Y(kU,$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.dr],null),$CLJS.LT));
$CLJS.Y(cU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kU],null)],null)],null)],null));
$CLJS.Y(dU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fU],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,1,[$CLJS.yi,$CLJS.Vi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uM,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yn,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cU],null)],null)],null)],null));
$CLJS.Y($CLJS.eU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gi,$CLJS.tD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.us,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Le(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.U.h(b))},a)}],null)],null));